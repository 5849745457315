<template>
	<el-dialog title="创建计划" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="45%">
		<div style="height:560px;">
			<div class="flex myClientimg-text-title flex-wrap-wrap">
				<h2>客户</h2>
				<div class="flex margin-left" v-if="Information.id">
					<div class="margin-right-lg">
						<svg class="icon" style="width:25px;height: 25px;" aria-hidden="true">
							<use xlink:href="#icon-icon-test"></use>
						</svg>
						<span class="margin-left-xs">{{Information.UserName}}</span>
					</div>
					<!-- <el-button @click="chooseContact">重新选择联系人</el-button> -->
					<div class="flex myClientimg-text-title">
						<div class="add-img" @click="chooseContact" style="margin-left: 10px;"><i
								class="iconfont icon-add"></i></div>
						<span class="myClient-text myClient-left-10">重新选择客户</span>
					</div>
				</div>

				<div v-else>
					<div v-if="Information" class="flex margin-left">
						<div class="add-img" @click="chooseContact" style="margin-left: 10px;"><i
								class="iconfont icon-add"></i></div>
						<span class="myClient-text myClient-left-10">选择客户</span>
					</div>
				
				</div>

			</div>
			<el-tabs v-model="activeName" @tab-click="handleClick" class="myClient-top-20">
				<el-tab-pane label="新建空白计划" name="first">
					<div>
						<div class="flex flex-center">
							<div class="margin-lg padding-xl cursor" @click="phonePlanAdd">
								<div>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-S_dianhuawenzhen"></use>
									</svg>
								</div>
								<div>电话计划</div>
							</div>
							<div class="margin-lg padding-xl cursor" @click="SMSPlanAdd">
								<div>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-duanxin1"></use>
									</svg>
								</div>
								<div>短信计划</div>
							</div>

						</div>
						<div class="flex flex-center">

							<div class="margin-lg padding-xl cursor" @click="weixPlanAdd">
								<div>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-weixin1"></use>
									</svg>
								</div>
								<div>微信计划</div>
							</div>
							<div class="margin-lg padding-xl cursor" @click="QQPlanAdd">
								<div>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-qq1"></use>
									</svg>
								</div>
								<div>QQ计划</div>
							</div>
							<!-- <div class="margin-lg padding-xl cursor" @click="tixinPlanAdd">
								<div>
									<svg class="icon" aria-hidden="true">
										<use xlink:href="#icon-tixing1"></use>
									</svg>
								</div>
								<div>定时提醒</div>
							</div> -->
						</div>
					</div>
				</el-tab-pane>
				<!-- <el-tab-pane label="最近使用" name="second">您还没有创建过销售计划</el-tab-pane>
				<el-tab-pane label="企业模板" name="third" style="height: 400px;">
					<div class="Clientcenter-left margin-right right-border" style="width: 25%;">
						<el-menu default-active="2" class="el-menu-vertical-demo">
							<el-submenu :index="item.value" v-for="(item, index) of EnterpriseTemplateList"
								:key="item.value">
								<template slot="title">
									<span>{{ item.label }}</span>
								</template>
								<el-menu-item-group>
									<el-menu-item v-for="(serve, res) of item.list" :key="res"
										@click="changeTemplate(serve.value)">
										<i :class="serve.icon" :style="{ color: serve.color }"></i>
										<span class="margin-left-xs">{{ serve.label }}</span>
									</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
						</el-menu>
					</div>
					<div class="Clientcenter-right padding-right-sm" style="width: 70%;overflow-y:auto ;height: 340px;">
						<meilEmplate v-if="Templatedetail == '1-1'"></meilEmplate>

						<ortherEmplate v-else></ortherEmplate>
					</div>

					<div class="myClient-position-absolute margin-top-xs">
						<div class="flex justify-content-flexEnd">
							<el-button type="primary">引用模板</el-button>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="个人模板" name="fourth">暂无可用的个人模板</el-tab-pane> -->
			</el-tabs>
		</div>


	</el-dialog>
</template>

<script>
	import meilEmplate from './mail-emplate.vue';
	import ortherEmplate from './other-emplate.vue';
	export default {
		components: {
			ortherEmplate,
			meilEmplate
		},
		props: {
			Visible: {
				type: Boolean,
				default: false
			},
			customersID: {
				type: Number,
				default: 0,
			}
		},
		data() {
			return {
				dialogVisible: false,
				activeName: 'first',
				editType: 1,
				ids: 0,
				Templatedetail: '1-1',
				Information: {},
				form: {
					name: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: '',
					resource: '',
					desc: ''
				},
				Information: {}, //选择的用户列列表
				//企业模板
				EnterpriseTemplateList: [{
						label: '初步意向，邀约见面',
						value: '1',
						list: [{
								icon: 'iconfont icon-youjian1',
								label: '第一天 邮件跟进',
								color: '#0086B3',
								value: '1-1'
							},
							{
								icon: 'iconfont icon-duanxin1',
								label: '第一天 短信提醒',
								color: '#418300',
								value: '1-2'
							},
							{
								icon: 'iconfont icon-qq',
								label: '第二天 QQ问候',
								color: '#0086B3',
								value: '1-3'
							},
							{
								icon: 'iconfont icon-duanxin1',
								label: '第二天 短信问候',
								color: '#418300',
								value: '1-4'
							},
							{
								icon: 'iconfont icon-tixing',
								label: '第三天 电话提醒',
								color: '#ff5500',
								value: '1-5'
							},
							{
								icon: 'iconfont icon-dianhua1',
								label: '第三天 电话促单',
								color: '#0086B3',
								value: '1-6'
							}
						]
					},
					{
						label: '初步见面，加深印象',
						value: '2',
						list: [{
								icon: 'iconfont icon-youjian1',
								label: '第一天 邮件跟进',
								color: '#0086B3',
								value: '1-1'
							},
							{
								icon: 'iconfont icon-duanxin1',
								label: '第一天 短信提醒',
								color: '#418300',
								value: '1-2'
							},
							{
								icon: 'iconfont icon-qq',
								label: '第二天 QQ问候',
								color: '#0086B3',
								value: '1-3'
							},
							{
								icon: 'iconfont icon-duanxin1',
								label: '第二天 短信问候',
								color: '#418300',
								value: '1-4'
							},
							{
								icon: 'iconfont icon-tixing',
								label: '第三天 电话提醒',
								color: '#ff5500',
								value: '1-5'
							},
							{
								icon: 'iconfont icon-dianhua1',
								label: '第三天 电话促单',
								color: '#0086B3',
								value: '1-6'
							}
						]
					},
					{
						label: '成交客户，稳定关系',
						value: '3',
						list: [{
								icon: 'iconfont icon-youjian1',
								label: '第一天 邮件跟进',
								color: '#0086B3',
								value: '1-1'
							},
							{
								icon: 'iconfont icon-duanxin1',
								label: '第一天 短信提醒',
								color: '#418300',
								value: '1-2'
							},
							{
								icon: 'iconfont icon-qq',
								label: '第二天 QQ问候',
								color: '#0086B3',
								value: '1-3'
							},
							{
								icon: 'iconfont icon-duanxin1',
								label: '第二天 短信问候',
								color: '#418300',
								value: '1-4'
							}
						]
					}
				]
			};
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
				if (this.customersID != 0) {
					this.ids = this.customersID
				}
				this.getViewCustomer()
			}
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('closepop');
			},
			getViewCustomer() {
				this.API.customerView_Customer(this.ids).then(res => {
					this.Information = res
				})
			},
			closeEditPop() {
				this.editVisible = false
				this.dialogVisible = false
			},
			editHandle(type) {
				this.editVisible = true;
				this.editType = type;
			},
			handleClick(tab) {
				this.activeName = tab.name;
			},
			chooseContact() {
				this.$Dialog.ChooseContact().then(res => {
					this.Information = res
				});
			},
			onAdvantageEditChange(type, html) {
				this.form[type] = html;
			},
			handleOpen(key, keyPath) {},
			changeTemplate(value) {
				this.Templatedetail = value;
			},
			//电话计划
			phonePlanAdd() {
				this.$Dialog.PhonePlanMessage({
					UserId: this.ids,
					customersList: this.Information
				})
			},
			//qq计划
			QQPlanAdd() {
				this.$Dialog.QQPlanMessage({
					UserId: this.ids,
					customersList: this.Information
				})
			},
			//短信计划
			SMSPlanAdd() {
				this.$Dialog.SMSPlanMessage({
					UserId: this.ids,
					customersList: this.Information
				})
			},
			//微信计划
			weixPlanAdd() {
				this.$Dialog.WeixinPlanMessage({
					UserId: this.ids,
					customersList: this.Information
				})
			},
			//提醒计划
			tixinPlanAdd() {
				this.$Dialog.TixinPlanMessage({
					UserId: this.ids,
					customersList: this.customersList
				})
			}
		}
	};
</script>

<style lang="scss" scoped>
	.icon {
		width: 60px;
		height: 60px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}
</style>
