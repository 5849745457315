<template>
	<div>
		<el-form ref="form" :model="form" label-width="80px">
			<el-form-item label="标题">
				<el-input v-model="form.titel"></el-input>
			</el-form-item>
			<el-form-item label="时间">
				<el-col :span="11">
					<el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;">
					</el-date-picker>
				</el-col>
				<el-col class="line" :span="2">-</el-col>
				<el-col :span="11">
					<el-time-picker placeholder="选择时间" v-model="form.date2" style="width: 100%;">
					</el-time-picker>
				</el-col>
			</el-form-item>

			<el-form-item label="类型">
				<el-input v-model="form.type"></el-input>
			</el-form-item>

			<el-form-item label="内容">
				<el-input type="textarea" v-model="form.desc"></el-input>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
	
		data() {
			return {
				editor2Val: '',
				form: {
					name: '',
					titel: '',
					date1: '',
					date2: '',
					type: '',
					theme: '',
					annex: '',
					desc: '',
				}
			}
		},
		methods: {
			
		}
	}
</script>

<style>
</style>
