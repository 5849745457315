<template>
	<el-dialog title="选择文件夹" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" width="30%">
		<div class="margin-tb-lg">

			<el-table :data="fileList" class="margin-top-lg">
				<el-table-column label="文件夹名称">
					<template slot-scope="scope"  >
						<i class="iconfont icon-wenjian3 line-orange"></i>
						<span style="margin-left: 10px" class="cursor" @click="handleClick(scope.$index, fileList)">{{ scope.row.FolderName }}</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</el-dialog>
</template>

<script>
	import AsList from '@/components/as-list'
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			},
			customerId: {
				type: Number,
				default: 0,
			}
		},
		components: {
			AsList
		},
		data() {
			return {
				dialogVisible: false,
				fileList: [],
				folder:{}
			}
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
				this.getSelectMyFolder(this.customerId)
			}
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('close',this.folder)
			},
			getSelectMyFolder(ids) {
				this.API.customerSelectMyFolder(ids).then(res => {
					this.fileList = res.data.rows
				})
			},
			handleClick(index,rows){
				this.folder.CustomerUserId = rows[index].CustomerId
				this.folder.FolderId =rows[index].id
				this.close()
			}
		}
	}
</script>

<style>
</style>
