<template>
	<el-dialog title="设置客户进展" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="20%">
		<div>
			<div class="text-grey">
				您已选择1个客户
			</div>
			<el-select v-model="ruleForm.Progress" placeholder="请选择" class="margin-top-sm" style="width: 100%;">
				<el-option v-for="(item,index) in ProgressList" :key="item.value" :label="item.label"
					:value="item.value"></el-option>
			</el-select>
			<div class="flex justify-content-flexEnd margin-top-xl">
				<el-button type="primary" @click="submitForm">保存</el-button>
				<el-button @click="close" style="padding-left: 10px;">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false
			},
			customersID: {
				type: Object,
				default: () => {
					return {}
				},
			}
		},
		data() {
			return {
				dialogVisible: false,
				ruleForm: {
					id: 0,
					Progress: '',
				},
				ProgressList: [{
						label: '新入户',
						value: 1,
					},
					{
						label: '沟通/邀约',
						value: 2,
					},
					{
						label: '上门咨询',
						value: 3,
					},
					{
						label: '异议排除',
						value: 4,
					},
					{
						label: '报名入学',
						value: 5,
					},
				]
			};
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
				this.ruleForm.id = this.customersID.ids
				if (this.customersID.progress == undefined) {
					this.ruleForm.Progress = ''
				} else {
					this.ruleForm.Progress = this.customersID.progress
				}
			}
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('closepop');
			},
			submitForm() {
				this.API.postEditCustomer(this.ruleForm).then(res => {
					if (res.code == 200) {
						this.$message.success("编辑成功")
						this.$emit('closepop');
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.icon {
		width: 50px;
		height: 50px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}
</style>
