<template>
	<el-dialog title="上传附件" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="30%">
		<div class="margin-tb-lg">

			<el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list='false'  :limit="9" :on-success="handleAvatarSuccess" :on-error="Errors">
				<el-button type="primary">添加</el-button><span style="margin-left: 10px;">已上传 {{fileList.length}}/9</span>
			</el-upload>
			<el-table :data="fileList" border class="margin-top-lg">
				<el-table-column label="名称" prop="name"></el-table-column>
				<el-table-column label="大小" prop="size"></el-table-column>
				<el-table-column label="状态">
					<i class="el-icon-success line-green"></i>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<label @click="handleClick(scope.$index, fileList)">删除</label>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</el-dialog>
</template>

<script>
	import AsList from '@/components/as-list'
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			}
		},
		components: {
			AsList
		},
		data() {
			return {
				dialogVisible: false,
				fileList: []

			}
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
			}
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('closepop')
			},
			handleClick(index,row) {
				this.fileList.splice(index,1) 
			},
			handleAvatarSuccess(res, file) { 
				this.fileList.push(file)
			},
			Errors(){
				  this.$message.error('文件上传失败');
			}
		}
	}
</script>

<style>
</style>
