<template>
	<el-dialog title="查找重复客户" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="60%">
		<el-tabs v-model="change" @tab-click="handleClicktab">
			<el-tab-pane label="个人客户" name="first"></el-tab-pane>

			<el-tab-pane label="企业客户" name="second"></el-tab-pane>
		</el-tabs>

		<div v-if="change=='first'">
			<div class="flex" >
				<div class="myClient-padding-20 flex">
					<el-radio label="按手机查找" v-model="text"></el-radio>
					<el-radio label="按邮箱查找" v-model="text"></el-radio>
				</div>
				<el-col :span="12" class="myClient-left-30">
					<el-input placeholder="输入完整的手机号"></el-input>
				</el-col>
			
				<div class="myClient-left-10">
					<el-button type="success">搜索</el-button>
				</div>
			</div>
			
			<div class="myClient-top-10">
				<span class="myClient-text">查询结果（10条匹配）</span>
			</div>
			<el-table :data="datalist" border style="height:400px;overflow-y:auto;" class="myClient-top-20">
				<el-table-column property="className" label="手机" align="center"></el-table-column>
				<el-table-column property="schoolName" label="重复客户"  align="center"></el-table-column>
				<el-table-column property="teacherName" label="操作" align="center"></el-table-column>
			</el-table>
		</div>
		
		<div v-if="change=='second'" style="height: 487px;">
			<div class="flex" >
				<div class="myClient-padding-20 ">
					按客户全名查找
				</div>
				<el-col :span="12" class="myClient-left-30">
					<el-input placeholder="输入完整的客户名称" v-model="ClientName"></el-input>
				</el-col>
			
				<div class="myClient-left-10">
					<el-button type="success">搜索</el-button>
				</div>
			</div>
			
			<el-table :data="datalist_enterprise" border style="height:400px;overflow-y:auto;" class="myClient-top-20" v-if="ClientName">
				<el-table-column property="className" label="客户名称" width="300" align="center"></el-table-column>
				<el-table-column property="schoolName" label="重复客户" width="200" align="center"></el-table-column>
				<el-table-column property="teacherName" label="操作" align="center"></el-table-column>
			</el-table>
			
			<div v-else class="myClient-center myClient-text">
					<i class="iconfont icon-order icon-font-size"></i>
					<div class="myClient-top-10">
						<div>
							没有搜索到相关结果，可能是：
						</div>
						<div>
							1、搜索条件不是完整的客户全名
						</div>
						<div>
							2、输入的客户全名没有重复的客户
						</div>
						<div>
							3、还不是客户，<label>点击这里</label>创建客户
						</div>
					</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		
		data() {
			return {
				dialogVisible: true,
				change: 'first',
				text: '',
				ClientName:'',
				datalist_enterprise:[{
					className: "塞巴斯蒂安",
					schoolName: "2",
					teacherName: "合并客户",
					}
				],
				datalist: [{
						className: "15487956823",
						schoolName: "2",
						teacherName: "合并客户",
					},
					{
						className: "15487956823",
						schoolName: "2",
						teacherName: "合并客户",
					},
					{
						className: "15487956823",
						schoolName: "2",
						teacherName: "合并客户",
					},
					{
						className: "15487956823",
						schoolName: "2",
						teacherName: "合并客户",
					},
					{
						className: "15487956823",
						schoolName: "2",
						teacherName: "合并客户",
					},
					{
						className: "15487956823",
						schoolName: "2",
						teacherName: "合并客户",
					},{
						className: "15487956823",
						schoolName: "2",
						teacherName: "合并客户",
					},{
						className: "15487956823",
						schoolName: "2",
						teacherName: "合并客户",
					},{
						className: "15487956823",
						schoolName: "2",
						teacherName: "合并客户",
					},{
						className: "15487956823",
						schoolName: "2",
						teacherName: "合并客户",
					},
				],
			}
		},
		methods: {
			close() {
				this.dialogVisible=false
			},
			handleClicktab(tab, event) {
				this.change=tab.name
			},
		}
	}
</script>

<style>
</style>
