<template>
	<el-dialog title="设置客户跟进记录" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="20%">
		<div>
			<div>
				<div class="text-grey">
					当前客户：
					<svg class="icon" style="width:25px;height: 25px;" aria-hidden="true">
						<use xlink:href="#icon-icon-test"></use>
					</svg>
					<span class="margin-left-xs">{{UserName}}</span>
				</div>
				<el-input type="textarea" class="margin-top-xl" v-model="ruleForm.Content"
					placeholder="请输入客户跟进记录,1000字以内" :rows="5"
					v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}" maxlength="1000" show-word-limit>
				</el-input>
			</div>

			<div class="flex justify-content-flexEnd margin-top-xl">
				<el-button type="primary" @click="submitForm">保存</el-button>
				<el-button @click="close" style="padding-left: 10px;">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false
			},
			customers: {
				type: Object,
				default: () => {
					return {}
				},
			}
		},
		data() {
			return {
				dialogVisible: false,
				UserName: '',
				ruleForm: {
					CustomerUserId: 0,
					Content: '',
				},
			};
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
				this.ruleForm.CustomerUserId = this.customers.ids
				this.UserName = this.customers.UserName
			}
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('closepop');
			},
			submitForm() {
				this.API.customerAddDynamicFollow(this.ruleForm).then(res => {
					if (res.code == 200) {
						this.ruleForm.Content=""
						this.$message.success("编辑成功")
						this.$emit('closepop');
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.icon {
		width: 50px;
		height: 50px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}
</style>
