<template>
	<el-dialog title="设置分组" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="25%">
		<div>
			<div>
				<div class="text-grey">
					您已选择1个客户
				</div>
				<div class="flex justify-content-between">
					<el-select v-model="ruleForm.Grouping" placeholder="请选择" class="margin-top-sm">
						<el-option v-for="(item,index) in datalist_enterprise" :key="index" :label="item.GroupName"
							:value="item.id"></el-option>
					</el-select>

					<label @click="editHandle" style="line-height: 50px;">新建分组</label>
				</div>
			</div>

			<div class="flex justify-content-between margin-top-xl">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="submitForm">保存</el-button>
			</div>
		</div>
		<addGroup :Visible="editVisible" @closepop="closeEditPop"></addGroup>
	</el-dialog>
</template>

<script>
	import addGroup from '@/components/add-group/addGroup.vue'
	export default {
		components: {
			addGroup
		},
		props: {
			Visible: {
				type: Boolean,
				default: false
			},
			customersID: {
				type: Object,
				default: () => {
					return {}
				},
			}
		},
		data() {
			return {
				dialogVisible: false,
				ruleForm: { //移动
					id: 0,
					Grouping : '',
				},

				datalist_enterprise: [],
				listQuery: {
					PageIndex: 1,
					PageSize: 20,
					Sort: [{
						"Field": "id",
						"Type": 0
					}]
				}
			};
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
				this.ruleForm.id = this.customersID.ids
				this.ruleForm.Grouping  = this.customersID.groupId
				this.SelectMyGroup()
			},

		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('closepop');
			},
			SelectMyGroup() {
				this.API.customerSelectMyGroup(this.listQuery).then(res => {
					this.datalist_enterprise = res.data.rows
				})
			},
			submitForm() {
				this.API.postEditCustomer(this.ruleForm).then(res => {
					if (res.code == 200) {
						this.$message.success("编辑成功")
						this.close();
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.icon {
		width: 50px;
		height: 50px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}
</style>
