<template>
	<el-dialog title="客户流转记录" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="60%">
		<div style="height: 560px;">
			<div class="flex justify-content-between">
				<div class="myClient-padding-20 cursor">
					<i class="iconfont icon-homepage"></i>
					<span class="myClient-weight">首页</span>
				</div>
				<div class="myClient-padding-20">
					<el-checkbox v-model="checked">仅显示处理中的任务</el-checkbox>
					<i class="el-icon-refresh myClient-left-10"></i>
				</div>
			</div>

			<el-table :data="datalist_enterprise" border style="height:400px;overflow-y:auto;" class="myClient-top-20">
				<el-table-column property="className" label="操作人" align="center"></el-table-column>
				<el-table-column property="schoolName" label="操作时间" align="center"></el-table-column>
				<el-table-column property="schoolName" label="操作类型" align="center"></el-table-column>

				<el-table-column property="teacherName" label="操作结果" align="center"></el-table-column>

			</el-table>
			<!-- <div class="myClient-center myClient-text">
				<i class="iconfont icon-order icon-font-size"></i>
				<div class="myClient-top-10">
					<div>
						没有找到相关记录
					</div>

				</div>
			</div> -->


			<div class="myClient-text flex justify-content-between myClient-top-10">
				<div>
					仅保留最近7天的操作记录
				</div>
				<div>
					(0条记录)
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		
		data() {
			return {
				dialogVisible: true,
				change: 'first',
				text: '',
				ClientName: '',
				checked: '',
				datalist_enterprise: [],
			}
		},
		
		methods: {
			close() {
				this.dialogVisible=false;
			}

		}
	}
</script>

<style>
</style>
